import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MyLogin from '../../components/particules/MyLogin/MyLogin'
import routes from '../../enums/routes'
import { useAuth } from '../../hooks/useAuth'
import './styles.scss'

const Login = () => {
  const { login, logout } = useAuth()
  const [connectionError, setConnectionError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const getError = (error) => {
    if (error?.status && error?.status === 401) {
      // API ERROR
      setConnectionError('Veuillez vérifier vos identifiants de connexion')
    } else if (error?.status && error?.status === 429) {
      // TOO MUCH FAILS
      setConnectionError('Trop de tentatives infructueuses')
    }
    setIsLoading(false)
    logout()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const email = formData.get('email')
    const password = formData.get('password')

    const emailFormat = new RegExp(
      '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'
    )

    if (!emailFormat.test(email)) {
      setConnectionError(
        "Le format des informations d'identification n'est pas valide."
      )
      return
    }

    setIsLoading(true)
    await login(
      {
        email,
        password,
      },
      getError
    )
    setIsLoading(false)
    navigate(routes.DASHBOARD, { replace: true })
  }

  return (
    <div>
      <div>
        <MyLogin
          onSubmit={handleSubmit}
          connectionError={connectionError}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default Login
