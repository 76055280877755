import { useAuth } from '../../../hooks/useAuth'
import MyButton from '../../atoms/MyButton/MyButton'
import './styles.scss'

const MyHeader = () => {
  const { logout } = useAuth()

  const isLoginPage = window.location.pathname === '/admin/login'

  return (
    <>
      {/* Mobile Header */}
      {!isLoginPage ? (
        <MyButton
          type="submit"
          colorText="white"
          colorBackground="#DC1F2B"
          rounded
          className="button-logout-mobile"
          onClick={(e) => {
            e.preventDefault()
            logout()
          }}
        >
          Se déconnecter
        </MyButton>
      ) : null}

      {/* Desktop Header */}
      {!isLoginPage ? (
        <MyButton
          type="submit"
          colorText="white"
          colorBackground="#DC1F2B"
          rounded
          className="button-logout-desktop"
          onClick={(e) => {
            e.preventDefault()
            logout()
          }}
        >
          Se déconnecter
        </MyButton>
      ) : null}
    </>
  )
}

export default MyHeader
