import moment from "moment";

const checkToken = (token, tokenExpiry) => {
  /* Check that the expiry token is not expired */
  if (token && tokenExpiry && moment(tokenExpiry).isBefore(moment())) {
    localStorage.removeItem('AUTH_TOKEN');
    localStorage.removeItem('AUTH_TOKEN_EXPIRY');
    localStorage.removeItem('AUTH_USER');
    window.location.reload();
  }
};

export default checkToken;