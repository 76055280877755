import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MyFeedbackResponse from '../../components/particules/MyFeedbackResponse/MyFeedbackResponse'
import routes from '../../enums/routes'
import './styles.scss'

const Feedback = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const code = location.state?.code
  const codeResponse = location.state?.codeResponse

  useEffect(() => {
    if (!code) {
      navigate(routes.DASHBOARD)
    }
  }, [])

  return (
    <div>
      <MyFeedbackResponse
        data={{
          code,
          codeResponse,
        }}
      />
    </div>
  )
}

export default Feedback
