import MyInput from '../../atoms/MyInput/MyInput'
import MyButton from '../../atoms/MyButton/MyButton'

import LegoImg from '../../../assets/img/lego/logo.png'

import './styles.scss'
import { useAuth } from '../../../hooks/useAuth'
import MyLoader from '../../atoms/MyLoader/MyLoader'

const MyLogin = (props) => {
  return (
    <div
      className="MyLogin"
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img
        className="MyLogin-logo"
        src={LegoImg}
        alt="Lego logo"
        style={{
          margin: 'auto',
          marginBottom: '50px',
        }}
      />

      <form
        onSubmit={props.onSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="login-input-container">
          <MyInput
            label="Identifiant LEGO"
            type="email"
            name="email"
            className="login-mail"
          />
          <MyInput
            label="Mot de passe"
            type="password"
            name="password"
            with-view-switcher
            className="login-password"
          />
          <p className="login-error-message">{props?.connectionError}</p>
        </div>
        {!props.isLoading ? (
          <MyButton
            type="submit"
            colorText="white"
            colorBackground="#DC1F2B"
            rounded
            className="MyLogin-button"
          >
            SE CONNECTER
          </MyButton>
        ) : (
          <div className="loader-wrapper">
            <MyLoader />
          </div>
        )}
      </form>
    </div>
  )
}

export default MyLogin
