import moment from "moment/moment";
import { useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../enums/routes";
import request from '../utils/fetch';
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("AUTH_USER", null);
  const [token, setToken] = useLocalStorage("AUTH_TOKEN", null);
  const [tokenExpiry, setTokenExpiry] = useLocalStorage("AUTH_TOKEN_EXPIRY", null);

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (info, getError) => {
    return new Promise(async (resolve, reject) => {
      request(`https://api.lego.spreadsuite.io/api/authentication-token`, 'post', {
        email: info.email,
        password: info.password
      }).then(async (response) => {

        setToken(response.token);
        setTokenExpiry(moment().add(3, 'hours').toISOString());
        setUser({
          email: info.email,
        });

        resolve(response);
        /* navigate(routes.DASHBOARD, { replace: true }); */

      }).catch(error => {
        console.info(error);
        reject(error);
        getError(error)
      });
    });
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setToken(null);
    setTokenExpiry(null);
    navigate(routes.LOGIN, { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      token,
      tokenExpiry,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
