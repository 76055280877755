import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LegoImg from '../../../assets/img/lego/logo.png'
import MyButton from '../../atoms/MyButton/MyButton'
import './styles.scss'
import request from '../../../utils/fetch'
import routes from '../../../enums/routes'
import moment from 'moment/moment'
import MyPopup from '../MyPopup/MyPopup'

const MyFeedbackResponse = (props) => {
  const navigate = useNavigate()
  const [info, setInfo] = useState(null)
  const [numberPhone, setNumberPhone] = useState(null)
  const [isPopupShown, setIsPopupShown] = useState(false)

  const formattedConfirmedDate = moment(
    props.data?.codeResponse?.confirmed_at,
    'YYYY-MM-DD HH:mm:ss'
  )
    .add(1, 'hours')
    .format('DD/MM/YYYY à HH[h]mm')

  const dataToShow = {
    valid: {
      title: 'Code valide',
      text: 'Le participant peut récupérer son lot.',
    },
    invalid: {
      title: 'Code déjà utilisé',
      text: 'Le lot a été retiré le : ',
    },
    notExist: {
      title: 'Code non existant',
      text: "Le code n'existe pas.",
    },
  }

  useEffect(() => {
    // Format number phone
    if (props.data?.codeResponse?.phone) {
      const parts = props.data?.codeResponse?.phone?.match(/.{1,2}/g)
      const formattedNumberPhone = parts.join(' ')
      setNumberPhone(formattedNumberPhone)
    }

    let info
    if (props.data?.codeResponse?.success) {
      info = dataToShow.valid
    }
    if (props.data?.codeResponse?.confirmed_at) {
      info = dataToShow.invalid
    }
    if (
      !props.data?.codeResponse?.success &&
      props.data?.codeResponse?.message === 'Code doesnt exist'
    ) {
      info = dataToShow.notExist
    }
    setInfo(info)
  }, [])

  const validateCode = () => {
    request(
      `https://api.lego.spreadsuite.io/api/asset/games/gift-form/asset/${26}/code/${
        props.data?.code
      }/${'use'}`
    )
      .then((response) => {
        navigate(routes.DASHBOARD, { replace: true })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const toggleValidateCodePopup = () => {
    setIsPopupShown(!isPopupShown)
  }

  return (
    <div className="login__text">
      <img className="lego-logo" src={LegoImg} alt="Lego logo" />

      <div className="code-info">
        <h3>{info?.title}</h3>
        <p>{info?.text}</p>
        {props.data?.codeResponse?.confirmed_at ? (
          <p
            className="formatted-date"
            style={{
              fontSize: '19px',
              marginTop: '0px',
            }}
          >
            {formattedConfirmedDate}
          </p>
        ) : null}
      </div>

      <div className="customer-info">
        <p className="name">
          {props.data?.codeResponse?.firstname}{' '}
          {props.data?.codeResponse?.lastname}
        </p>
        <p className="email">{props.data?.codeResponse?.email}</p>
        {numberPhone ? <p className="telephone">{numberPhone}</p> : null}
      </div>

      <div className="buttons-container">
        <MyButton
          type="submit"
          colorText="white"
          colorBackground="#C4C4C4"
          rounded
          className="button-retour"
          onClick={(e) => {
            e.preventDefault()
            navigate(routes.DASHBOARD, { replace: true })
          }}
        >
          RETOUR
        </MyButton>
        {props.data?.codeResponse?.confirmed_at === null ||
        props.data?.codeResponse?.message === null ? (
          <MyButton
            type="submit"
            colorText="white"
            colorBackground="#DC1F2B"
            rounded
            className="button-valider"
            onClick={(e) => {
              e.preventDefault()
              toggleValidateCodePopup()
            }}
          >
            VALIDER LE RETRAIT
          </MyButton>
        ) : null}
      </div>

      {/* POPUP */}
      <MyPopup
        handleValidateCode={validateCode}
        handleIsOpen={toggleValidateCodePopup}
        isOpen={isPopupShown}
      />
    </div>
  )
}

export default MyFeedbackResponse
