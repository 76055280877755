import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import { AuthProvider } from './hooks/useAuth'

import SnowImg from './assets/img/lego/snow.png'
import TreeImg from './assets/img/lego/tree.png'
import AnimalImg from './assets/img/lego/animal.png'
import MyButton from './components/atoms/MyButton/MyButton'
import MyHeader from './components/particules/MyHeader/MyHeader'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <div className="app-wrapper">
          <div className="app-background">
            {/* Cards */}
            <div className="app-root">
              <MyHeader />
              <App />
            </div>

            {/* Snow */}
            <img className="snow-1" src={SnowImg} alt="Lego logo" />
            <img className="snow-2" src={SnowImg} alt="Lego logo" />
            <img className="snow-3" src={SnowImg} alt="Lego logo" />

            {/* Tree */}
            <img className="tree-1" src={TreeImg} alt="Lego logo" />

            {/* Animal */}
            <img className="animal-1" src={AnimalImg} alt="Lego logo" />
          </div>
        </div>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
