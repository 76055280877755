import { Navigate, Route, Routes } from 'react-router-dom'
import routes from './enums/routes'

import Login from './pages/login/index'
import Dashboard from './pages/dashboard/index'
import Feedback from './pages/feedback/index'
import { ProtectedRoute } from './middlewares/ProtectedRoute'
import checkToken from './utils/checkToken'

const App = () => {
  /* The protected routes redirect automatically to login in case the user is not authenticated */

  const token = localStorage.getItem('AUTH_TOKEN')
  const tokenExpiry = localStorage.getItem('AUTH_TOKEN_EXPIRY')
  checkToken(token, tokenExpiry)

  return (
    <Routes>
      {/* Redirect to login when 404 and non-auth */}
      <Route
        path="*"
        element={
          <ProtectedRoute>
            {/* Redirect to dashboard when 404 and auth */}
            <Navigate to={routes.DASHBOARD} />
          </ProtectedRoute>
        }
      />
      <Route path={routes.LOGIN} element={<Login />} />
      <Route
        path={routes.DASHBOARD}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.FEEDBACK}
        element={
          <ProtectedRoute>
            <Feedback />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}

export default App
