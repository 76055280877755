import MyButton from '../../atoms/MyButton/MyButton'
import MyInput from '../../atoms/MyInput/MyInput'
import LegoImg from '../../../assets/img/lego/logo.png'
import './styles.scss'
import MyLoader from '../../atoms/MyLoader/MyLoader'

const MyEnterCode = (props) => {
  return (
    <div className="myEnterCode">
      <img
        className="lego-logo"
        src={LegoImg}
        alt="Lego logo"
        style={{
          margin: 'auto',
          marginBottom: '50px',
        }}
      />
      <form
        onSubmit={props.handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        className="code-form"
      >
        <div className="myEnterCode__container">
          <MyInput
            label="Code client LEGO :"
            type="text"
            name="code"
            className="input"
            handleChange={props.handleChange}
          />
        </div>
        {props.codeFormatError ? (
          <p className="error-message">Le format du code est incorrect</p>
        ) : null}
        {!props.isLoading ? (
          <MyButton
            type="submit"
            colorText="white"
            colorBackground="#DC1F2B"
            rounded
            className="button-validate"
          >
            VALIDER
          </MyButton>
        ) : (
          <div className="loader-wrapper-code">
            <MyLoader />
          </div>
        )}
      </form>
    </div>
  )
}

export default MyEnterCode
