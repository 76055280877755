import { useEffect, useState } from 'react'
import MyButton from '../../atoms/MyButton/MyButton'
import './styles.scss'

const MyPopup = (props) => {
  return (
    <>
      {props.isOpen ? (
        <div className="popup-container">
          <div className="popup">
            <p className="popup-title">Êtes-vous sûr de vouloir valider ?</p>
            <div className="popup-buttons-container">
              <MyButton
                type="submit"
                colorText="white"
                colorBackground="#DC1F2B"
                rounded
                className="button-valider"
                onClick={(e) => {
                  e.preventDefault()
                  props.handleValidateCode()
                }}
              >
                OUI
              </MyButton>
              <MyButton
                type="submit"
                colorText="white"
                colorBackground="#C4C4C4"
                rounded
                className="button-retour"
                onClick={(e) => {
                  e.preventDefault()
                  props.handleIsOpen()
                }}
              >
                NON
              </MyButton>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default MyPopup
