import './styles.scss'
import SeePassword from '../../../assets/img/see-text.svg'
import NotSeePassword from '../../../assets/img/not-see-text.svg'
import { useState } from 'react'
import { useRef } from 'react'

const MyInput = ({ label, ...props }) => {
  const [isInfoShowed, setIsInfoShowed] = useState(false)
  const inputRef = useRef(null)

  const handleSwitchView = () => {
    if (inputRef.current.type === 'password') {
      inputRef.current.type = 'text'
    } else {
      inputRef.current.type = 'password'
    }
    setIsInfoShowed(!isInfoShowed)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <label
        style={{
          fontSize: '14px',
          fontWeight: '500',
        }}
      >
        {label}
      </label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          border: props['with-view-switcher'] ? '1.5px solid black' : 'none',
          height: '40px',
          width: '100%',
        }}
      >
        <input
          {...props}
          onChange={props.handleChange}
          style={{
            border: props['with-view-switcher'] ? 'none' : '1.5px solid black',
            height: '100%',
            width: '100%',
            padding: '0 8px',
          }}
          ref={inputRef}
          className={props.className}
        />
        {props['with-view-switcher'] && props.type === 'password' ? (
          <span
            style={{
              cursor: 'pointer',
              marginRight: '8px',
              marginLeft: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleSwitchView}
          >
            <img
              src={isInfoShowed ? NotSeePassword : SeePassword}
              alt="see password"
              style={{
                width: '32px',
                height: '32px',
                padding: '8px',
              }}
            />
          </span>
        ) : null}
      </div>
    </div>
  )
}

export default MyInput
