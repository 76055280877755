import './styles.scss'

const MyButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      style={{
        backgroundColor: props.colorBackground,
        color: props.colorText,
        borderRadius: props.rounded ? '4px' : '0',
        cursor: 'pointer',
        border: 'none',
        padding: '10px 16px',
        fontSize: '12px',
        fontFamily: 'cera-pro-bold',
        /* maxWidth: '133px',
        maxHeight: '31px', */
        ...props.style,
      }}
      type={props.type}
      className={props.className}
    >
      {props.children}
    </button>
  )
}

export default MyButton
