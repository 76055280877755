import moment from 'moment'
import { Navigate } from 'react-router-dom'
import routes from '../enums/routes'
import { useAuth } from '../hooks/useAuth'

export const ProtectedRoute = (props) => {
  const { user, token, tokenExpiry, logout } = useAuth()
  if (
    !user ||
    !token ||
    !tokenExpiry ||
    moment(tokenExpiry).isBefore(moment())
  ) {
    // user is not authenticated
    logout()
    return <Navigate to={props.redirectTo ?? routes.LOGIN} />
  }
  return props.children
}
