import { useNavigate } from 'react-router-dom'
import MyEnterCode from '../../components/particules/MyEnterCode/MyEnterCode'
import request from '../../utils/fetch'
import routes from '../../enums/routes'
import './styles.scss'
import { useState } from 'react'

const Dashboard = () => {
  const navigate = useNavigate()
  const [codeFormatError, setCodeFormatError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    const data = new FormData(e.target)
    const code = data.get('code')
    const codeFormat = new RegExp('^[A-Z0-9]{7}$')

    if (codeFormat.test(code)) {
      request(
        `https://api.lego.spreadsuite.io/api/asset/games/gift-form/asset/${26}/code/${code}/${'check'}`
      ).then((data) => {
        setIsLoading(false)
        navigate(routes.FEEDBACK, { state: { code, codeResponse: data } })
      })
    } else {
      setIsLoading(false)
      setCodeFormatError(true)
    }
  }

  const handleChange = (e) => {
    let uppercasedChar = e.target.value.toUpperCase()
    e.target.value = uppercasedChar.slice(0, 7)
  }

  return (
    <div>
      <MyEnterCode
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isLoading={isLoading}
        codeFormatError={codeFormatError}
      />
    </div>
  )
}

export default Dashboard
